'use client'

import { useEffect, useState } from 'react'
import { Swiper as SwiperType } from 'swiper'

import useViewport from 'core/hooks/useViewport'

export default function useControls({
  total = 0,
  centeredSlides,
  slidesPerView,
  controls,
}: {
  total?: number
  centeredSlides?: boolean
  slidesPerView?: number | 'auto'
  controls?: boolean
}): typeof returnObj {
  const viewport = useViewport()
  const [swipe, setSwipe] = useState<SwiperType | null>(null)
  const [showControls, setShowControls] = useState(controls)

  const isRoundCenter = (total / 2) % 1 === 0
  const centerSlide = isRoundCenter ? total / 2 + 0.5 : Math.round(total / 2)
  const initialSlide = centeredSlides ? centerSlide - 1 : 0
  const [currentSlide, setCurrentSlide] = useState(initialSlide)

  swipe?.on('slideChange', () => {
    setCurrentSlide(swipe?.isEnd ? total - 1 : swipe.activeIndex)
  })

  useEffect(() => {
    swipe?.slideTo(initialSlide, 0, false)
  }, [centeredSlides, viewport, total, initialSlide, swipe])

  useEffect(() => {
    if (controls && typeof slidesPerView === 'number') {
      setShowControls(total > slidesPerView)
    }
  }, [slidesPerView, total])

  const prevDisabled = currentSlide === 0
  const nextDisabled = currentSlide === total - 1

  const returnObj = {
    swipe,
    setSwipe,
    showControls,
    currentSlide,
    prevDisabled,
    nextDisabled,
    initialSlide,
  }

  return returnObj
}
