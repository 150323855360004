import { stripRootfromUrl } from 'core/utils/url'

import Button from 'components/Button'

import { PageSliderFragment } from '../PageSlider.dato.generated'

import Media from './Media'

import type { VideoQualityType } from './Media'

type SliderType = PageSliderFragment['content'][number]

function ImageButton({ to, ...props }: (typeof Button)['arguments']): JSX.Element {
  return to !== '/' && to ? (
    <Button
      styleType="base"
      className="focus-sm aspect-[4/6] w-full p-0 leading-none focus-in "
      {...props}
      to={to}
    />
  ) : (
    <div
      className="focus aspect-[4/6] h-full w-full  leading-none "
      {...props}
    />
  )
}

export default function SliderBlock({
  medium,
  link,
  title,
  sizes,
  maxQuality,
}: SliderType & { sizes: string; maxQuality?: VideoQualityType }): JSX.Element {
  const to = link ? stripRootfromUrl(link) : null

  return (
    <div className="mt-xs">
      <ImageButton to={to}>
        <Media
          {...medium}
          sizes={sizes}
          link={null}
          maxQuality={maxQuality}
        />
      </ImageButton>

      {title && (
        <div className="flex w-full grow flex-row items-center justify-between bg-ghost pt-sm text-md font-bold leading-none">
          {title}
        </div>
      )}
    </div>
  )
}
