import { default as MarkdownJSX } from 'markdown-to-jsx'

import { stripRootfromUrl } from 'core/utils/url'

import Button from 'components/Button'

export default function Markdown({ children }: { children: string }): JSX.Element {
  return (
    <MarkdownJSX
      options={{
        wrapper: 'article',
        overrides: {
          a: ({ href, children }: { href: string; children: string }) => (
            <Button
              styleType="text"
              to={stripRootfromUrl(href)}
            >
              {children}
            </Button>
          ),
        },
      }}
    >
      {children as string}
    </MarkdownJSX>
  )
}
