import { newLinesToP } from 'core/utils/text'

import Image from 'components/Image'

import Block from './components/Block'
import { PageUspFragment } from './PageUSP.dato.generated'

export type { PageUspFragment } from './PageUSP.dato.generated'
export const typename = 'PageUspRecord'

export default function PageUSP({ title, usps }: PageUspFragment): JSX.Element {
  return (
    <Block className="mx-auto my-0 w-full max-w-content flex-col flex-wrap justify-center bg-cotton px-md py-2xl">
      <h2 className="mb-xl flex w-full justify-center">{title}</h2>
      <div className="mx-auto my-0 flex flex-row flex-wrap justify-center">
        {usps.map(({ title, body, icon }) => (
          <div
            key={title}
            className="h-fit basis-1/2 px-sm py-md text-center sm:p-md xl:basis-1/4 "
          >
            <Image
              alt={title}
              src={icon?.url || ''}
              style={{ width: 100, height: 'auto', margin: 'auto' }}
            />
            <h4 className="mx-0 my-md text-center">{title}</h4>
            <div>{body && newLinesToP(body)}</div>
          </div>
        ))}
      </div>
    </Block>
  )
}
