'use client'

import useIsInViewport from 'core/hooks/useIsInViewport'
import useIsTouchDevice from 'core/hooks/useIsTouchDevice'
import useSession from 'core/hooks/useSession'

import HorizontalScroll from 'components/HorizontalScroll'
import ProductCard from 'components/ProductCard'

import Block from './components/Block'
import { PageCollectionFragment } from './PageCollection.dato.generated'

export type { PageCollectionFragment } from './PageCollection.dato.generated'
export const typename = 'PageCollectionRecord'

export default function PageCollection({
  title,
  products,
  controls,
  hidePricing,
  fill,
  compact,
}: PageCollectionFragment & { products?: Algolia.Product[] }): JSX.Element {
  const isDesktop = useIsInViewport('sm')
  const isTouchDevice = useIsTouchDevice()
  const { channel } = useSession()

  return (
    <Block
      forceNoPadding
      className="mx-auto my-0 max-w-content no-select"
    >
      <HorizontalScroll
        className="m-0"
        Component={ProductCard}
        fill={fill}
        slidesPerViewport={
          !!compact
            ? {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 4,
                xl: 4,
                '2xl': 4,
              }
            : {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                '2xl': 4,
              }
        }
        trackingCategory="dato"
        title={title}
        controls={controls}
        items={
          products?.map((product) => ({
            product,
            channel,
            touchDevice: isTouchDevice,
            smallControls: !isDesktop,
            url: product.link,
            hidePricing,
            direct: true,
          })) || []
        }
      />
    </Block>
  )
}
