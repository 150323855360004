import Block from './components/Block'
import { PageExternalVideoFragment } from './PageExternalVideo.dato.generated'

export type { PageExternalVideoFragment } from './PageExternalVideo.dato.generated'
export const typename = 'PageExternalVideoRecord'

export default function PageHeaderSplit({ video }: PageExternalVideoFragment): JSX.Element | null {
  return video?.provider === 'youtube' ? (
    <Block>
      <iframe
        className="mx-auto my-0 block h-auto w-full max-w-content-2/3 border-none"
        style={{ aspectRatio: `${video.width}/${video.height}` }}
        src={`https://www.youtube-nocookie.com/embed/${video.providerUid}?controls=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={video.title || ''}
      />
    </Block>
  ) : null
}
