'use client'

import { Scrollbar, FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'

import './swiper.css'

import useI18n from 'core/hooks/useI18n'
import useViewport from 'core/hooks/useViewport'
import { AnalyticsCategory } from 'core/types/tracking'
import mergeClasses from 'core/utils/mergeClasses'
import { analyticsTracking } from 'core/utils/tracking'

import Button from 'components/Button'

import useControls from './useControls'

function getMaxDivider(total: number, requested: number, fill?: boolean): number {
  const value = Math.min(total, requested)
  return fill ? value : value + 0.5
}

type slideWidthType = {
  xs: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
  '2xl': string
}

export default function HorizontalScroll<TObj, T extends TObj & { items: any[] }>({
  title,
  Component,
  items,
  centeredSlides,
  className,
  trackingCategory,
  controls,
  spaceBetween,
  noPadding,
  fill,
  slidesPerViewport,
}: {
  title?: string | null
  Component: React.FC<T['items'][number]>
  items: any[]
  centeredSlides?: boolean
  className?: string
  trackingCategory?: keyof typeof AnalyticsCategory
  controls?: boolean
  spaceBetween?: number
  noPadding?: boolean
  fill?: boolean
  slidesPerViewport?: {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    '2xl': number
  }
}): JSX.Element {
  const { t } = useI18n()
  const viewport = useViewport()
  let slidesPerView: number = slidesPerViewport?.[viewport as keyof typeof slidesPerViewport] || 0

  if (slidesPerView) {
    slidesPerView = slidesPerView >= items.length ? items.length : !fill ? slidesPerView + 0.5 : slidesPerView
  }

  const { swipe, setSwipe, showControls, prevDisabled, nextDisabled, initialSlide } = useControls({
    centeredSlides,
    total: items.length,
    slidesPerView,
    controls,
  })

  const slideWidth = Object.entries(slidesPerViewport || {}).reduce((sum, [key, value]) => {
    if (key === '2xl') {
      sum[key as keyof slideWidthType] = `calc(1700px / ${getMaxDivider(items.length, value, fill)})`
    } else {
      sum[key as keyof slideWidthType] = `calc(var(--content-w) / ${getMaxDivider(items.length, value, fill)})`
    }

    return sum
  }, {} as slideWidthType)

  return (
    <div
      className={mergeClasses(
        'flex horizontal-scroll flex-col items-start no-select my-lg mx-0 w-full relative',
        className
      )}
      style={
        {
          '--swiper-padding': !noPadding && '8px',
          '--swiper-padding-bottom': showControls && '24px',
          ...(!!slideWidth.xs
            ? {
                '--swiper-slide-w': slideWidth.xs,
                '--swiper-slide-w-sm': slideWidth.sm || slideWidth.xs,
                '--swiper-slide-w-md': slideWidth.md || slideWidth.sm || slideWidth.xs,
                '--swiper-slide-w-lg': slideWidth.lg || slideWidth.md || slideWidth.sm || slideWidth.xs,
                '--swiper-slide-w-xl':
                  slideWidth.xl || slideWidth.lg || slideWidth.md || slideWidth.sm || slideWidth.xs,
                '--swiper-slide-w-2xl':
                  slideWidth['2xl'] ||
                  slideWidth.xl ||
                  slideWidth.lg ||
                  slideWidth.md ||
                  slideWidth.sm ||
                  slideWidth.xs,
              }
            : {}),
        } as React.CSSProperties
      }
    >
      {(title || controls) && (
        <div
          className={mergeClasses(
            'flex justify-between py-0 px-md w-full my-sm mx-0 items-center md:px-lg md:my-md',
            !!showControls && !title ? 'flex-row-reverse' : 'flex-row'
          )}
        >
          {title && <h2 className="m-0 text-left text-2xl font-bold">{title}</h2>}
          {showControls && (
            <div className="flex items-center">
              <Button
                className={mergeClasses(
                  'mr-sm !h-button !w-button border !focus-rd-xl',
                  prevDisabled ? 'border-disabled' : 'border-ink'
                )}
                styleType="icon"
                icon="chevronLeft"
                disabled={prevDisabled}
                onClick={() => swipe?.slidePrev()}
              >
                {t('button.previous')}
              </Button>
              <Button
                className={mergeClasses(
                  '!h-button !w-button border !focus-rd-xl',
                  nextDisabled ? 'border-disabled' : 'border-ink'
                )}
                styleType="icon"
                icon="chevronRight"
                disabled={nextDisabled}
                onClick={() => swipe?.slideNext()}
              >
                {t('button.next')}
              </Button>
            </div>
          )}
        </div>
      )}
      <Swiper
        onSwiper={setSwipe}
        modules={[Scrollbar, FreeMode]}
        grabCursor
        slidesPerView={slidesPerView || 'auto'}
        centeredSlides={centeredSlides}
        initialSlide={initialSlide}
        spaceBetween={spaceBetween}
        scrollbar={{
          enabled: true,
          hide: !controls,
        }}
        freeMode={{
          enabled: true,
          sticky: true,
          momentumRatio: 0.6,
          momentumVelocityRatio: 0.6,
        }}
        onSliderFirstMove={() => {
          trackingCategory &&
            analyticsTracking({ action: 'scroll', category: trackingCategory, label: 'horizontalScroll' })
        }}
      >
        {items?.map((item, index) => (
          <SwiperSlide key={`${item?.keyPrefix || 'key'}-${index}`}>
            <Component {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
