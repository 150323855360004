import mergeClasses from 'core/utils/mergeClasses'

export default function Marquee({
  className,
  children,
}: {
  className?: string
  children?: string | null
}): JSX.Element | null {
  if (!children) {
    return null
  }

  const content = new Array(3).fill(children)

  return (
    <div
      className={mergeClasses(
        'w-full whitespace-nowrap font-bold h-auto leading-[15vw] flex items-center overflow-hidden my-2xl',
        className
      )}
    >
      <div className="animate-infinite-left">
        {content.map((text, index) => (
          <span
            key={index}
            className="h-full px-sm text-5xl sm:text-[10vw]"
          >
            {text}
          </span>
        ))}
      </div>
    </div>
  )
}
