import { newLinesToBrakes } from 'core/utils/text'

import Block from './components/Block'
import DatoText from './components/DatoText'
import { PageHeaderSplitFragment } from './PageHeaderSplit.dato.generated'

export type { PageHeaderSplitFragment } from './PageHeaderSplit.dato.generated'
export const typename = 'PageHeaderSplitRecord'

export default function PageHeaderSplit({ title, description }: PageHeaderSplitFragment): JSX.Element {
  return (
    <Block>
      <header className="mx-auto max-w-content-2/3 p-md sm:flex sm:px-lg sm:py-2xl">
        <h1 className="mb-md sm:w-1/2 sm:pr-md">{newLinesToBrakes(title)}</h1>
        <div className="sm:w-1/2">
          <DatoText>{description?.value}</DatoText>
        </div>
      </header>
    </Block>
  )
}
