'use client'

import mergeClasses from 'core/utils/mergeClasses'

import Block from './components/Block'
import GridTitleOverlay from './components/GridTitleOverlay'
import Media from './components/Media'
import { PageCoverFragment } from './PageCover.dato.generated'

export type { PageCoverFragment } from './PageCover.dato.generated'

export const typename = 'PageCoverRecord'

export default function PageCover({ slim, blocks }: PageCoverFragment): JSX.Element | null {
  const hasMultiple = (blocks?.length || 0) > 1
  return (
    <Block forceNoPadding={!hasMultiple}>
      <div
        className={mergeClasses(
          'flex flex-col md:flex-row w-full',
          !!slim ? 'h-[75vw] md:max-h-[35vh]' : !hasMultiple && 'h-[125vw] md:max-h-[95vh]'
        )}
      >
        {blocks.map(
          ({ medium, videoSoundControl, title, alignment, caption, links, backgroundColor, textColor }, index) => (
            <div
              className={mergeClasses(
                'relative overflow-hidden flex-col-reverse my-0 mx-auto w-full h-full md:flex-row',
                hasMultiple && 'first:mb-sm last:mt-sm md:first:mb-0 md:last:mt-0 md:first:mr-sm md:last:ml-sm'
              )}
              key={index}
            >
              <Media
                {...medium}
                priority
                controls={videoSoundControl}
                alt={title}
                sizes={'100vw'}
                maxQuality="high"
                className={hasMultiple ? 'aspect-square' : ''}
              />
              {title && (
                <GridTitleOverlay
                  aligned={alignment as React.ComponentProps<typeof GridTitleOverlay>['aligned']}
                  color={backgroundColor?.hex}
                  textColor={textColor?.hex}
                  title={title}
                  caption={caption}
                  links={links}
                />
              )}
            </div>
          )
        )}
      </div>
    </Block>
  )
}
