import mergeClasses from 'core/utils/mergeClasses'
import { newLinesToBrakes } from 'core/utils/text'

import colors from 'components/AdamTheme/theme/colors'
import Button from 'components/Button'

import Caption from './Caption'

type TitleProps = {
  aligned?: 'topleft' | 'topright' | 'bottomright' | 'bottomleft'
  color?: string
  textColor?: string
  caption?: string | null
  title?: string
  links?: { title?: string | null; link?: string | null }[] | null
}

export default function GridTitleOverlay({
  aligned,
  color,
  textColor,
  caption,
  title,
  links,
}: TitleProps & { color?: string; textColor?: string }): JSX.Element {
  const hasButton = (links?.length || 0) > 0
  return (
    <div className="pointer-events-none absolute top-0 h-full w-full">
      <div className="relative mx-auto grid h-full w-full max-w-content grid-cols-16 grid-rows-12">
        <div
          className={mergeClasses(
            'absolute w-full h-full',
            {
              topleft: 'top-0 row-start-2 col-start-2 row-end-2  col-end-2',
              topright: 'top-0 row-start-2 col-start-15 row-end-2 col-end-15',
              bottomright: 'bottom-0 row-start-11 col-start-15 row-end-11 col-end-15',
              bottomleft: 'bottom-0 row-start-11 col-start-2 row-end-11 col-end-2',
            }[aligned || 'bottomleft']
          )}
        >
          <div
            className={mergeClasses(
              'absolute  pointer-events-auto bg-transparent text-ink flex flex-col',
              {
                topleft: 'left-0 top-0 items-start',
                topright: 'top-0 right-0 items-end',
                bottomright: 'bottom-0 right-0 items-end',
                bottomleft: 'bottom-0 left-0 items-start',
              }[aligned || 'bottomleft'],
              color && 'py-md px-md md:px-lg rounded-sm',
              color && hasButton && 'pb-lg'
            )}
            style={{ backgroundColor: color, color: textColor }}
          >
            <Caption
              className={mergeClasses('mt-sm empty:none')}
              style={{ color: textColor }}
            >
              {caption}
            </Caption>
            <h1 className="mb-sm whitespace-nowrap font-bold">{newLinesToBrakes(title || '')}</h1>
            <div className="[&:not(:first)]:ml-md">
              {links?.map(({ title, link }, index) => (
                <Button
                  className="border border-solid"
                  style={{
                    color: textColor || colors.ink,
                    borderColor: textColor || colors.ink,
                  }}
                  key={index}
                  styleType="button"
                  inverted
                  to={link}
                >
                  {title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
