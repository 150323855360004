import mergeClasses from 'core/utils/mergeClasses'

import viewports from 'components/AdamTheme/theme/viewports'

import Block from './components/Block'
import DatoText from './components/DatoText'
import Media from './components/Media'
import { PageSplitFragment } from './PageSplit.dato.generated'

export type { PageSplitFragment } from './PageSplit.dato.generated'
export const typename = 'PageSplitRecord'

export default function PageSplit({
  body,
  medium,
  fill,
  index,
  nextSame,
}: PageSplitFragment & { index: number; nextSame?: boolean }): JSX.Element {
  const isAlternate = index % 2 === 0

  return (
    <Block
      className={mergeClasses(
        'max-w-content my-0 mx-auto flex flex-col-reverse relative',
        isAlternate ? 'md:flex-row-reverse' : 'md:flex-row',
        nextSame && '!mb-0'
      )}
    >
      <div className="p-lg text-left md:flex md:w-1/2 md:flex-col md:items-center md:justify-center md:p-xl md:[&>div]:max-w-[25vw]">
        <div>
          <DatoText>{body?.value}</DatoText>
        </div>
      </div>
      <div
        className={mergeClasses(
          'aspect-[1/1] flex md:w-1/2',
          !fill && isAlternate ? 'md:p-lg md:pl-0 lg:p-xl lg:pl-0' : 'md:p-lg md:pr-0 lg:p-xl lg:pr-0'
        )}
      >
        <Media
          {...medium}
          sizes={`(min-width: ${viewports.md}px) 50vw, 100vw`}
          maxQuality="high"
        />
      </div>
    </Block>
  )
}
