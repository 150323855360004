'use client'

import reactStringReplace from 'react-string-replace'
import { CountUp } from 'use-count-up'

import useIsInViewport from 'core/hooks/useIsInViewport'
import useSWR from 'core/hooks/useSWR'
import mergeClasses from 'core/utils/mergeClasses'

import type { ReturnType } from 'app/api/store/greenstory/route'

import GreenstoryLogo from 'views/Pdp/assets/greenstory.svg'

import Button from 'components/Button'
import GreenstoryProject from 'components/GreenstoryProject'
import Image from 'components/Image'
import Markdown from 'components/Markdown'

import Block from './components/Block'
import Media from './components/Media'
import { PageGreenstoryFragment } from './PageGreenstory.dato.generated'

export type { PageGreenstoryFragment } from './PageGreenstory.dato.generated'
export const typename = 'PageGreenstoryRecord'

export default function PageGreenstory({
  viewtype,
  title,
  subTitle,
  background,
}: PageGreenstoryFragment): JSX.Element | null {
  const { data } = useSWR<ReturnType>('/api/store/greenstory')
  const isTablet = useIsInViewport('sm')

  const metrics = data?.offset_summary?.find((value) => !!value) || null
  const animationTime = 5 * 60
  const startPercentage = 1 - (animationTime * 2) / (metrics?.value || 0)

  if (!metrics) {
    return null
  }

  return (
    <Block
      className={mergeClasses(
        'relative flex-col p-md mx-auto max-w-content justify-center items-center w-full py-2xl',
        !!background ? 'text-ghost' : 'text-ink'
      )}
    >
      {background && (
        <div className="absolute -z-1 col-start-1 col-end-[17] row-start-1 row-end-[13] flex size-full flex-col justify-center after:absolute after:block after:size-full after:bg-ink/[55] after:content-['']">
          <Media
            {...background}
            priority
            controls={false}
            alt={title}
            aspectRatio={[1, 1]}
            sizes={isTablet ? '100vw' : '150vw'}
            maxQuality="high"
          />
        </div>
      )}
      <div className="px-lg">
        {title && (
          <h1 className="text-center">
            {reactStringReplace(title || '', /{{offset}}/, (_, index) => (
              <span key={index}>
                <CountUp
                  easing="linear"
                  isCounting
                  decimalPlaces={0}
                  duration={animationTime}
                  start={metrics.value * startPercentage}
                  end={metrics.value}
                />
                {metrics.units}
              </span>
            ))}
          </h1>
        )}
        {subTitle && (
          <div className="mb-lg text-center">
            <Markdown>{subTitle}</Markdown>
          </div>
        )}
        {viewtype === 'offset' && (
          <ul className="grid grid-cols-2 flex-row md:grid-cols-3 lg:grid-cols-6 ">
            {metrics.equivalences?.map(({ value, help_text, image_url, description }, index) => (
              <li
                key={`metrics-${index}`}
                className="mx-md mb-sm flex flex-col items-center justify-start"
              >
                <Image
                  src={image_url}
                  alt={help_text}
                  className={mergeClasses('!w-2xl !h-2xl mr-sm', !!background && 'invert')}
                />

                <div className="mb-sm mr-sm items-start justify-center text-2xl font-bold md:mr-sm">
                  <CountUp
                    easing="linear"
                    isCounting
                    decimalPlaces={0}
                    duration={animationTime}
                    start={value * startPercentage}
                    end={value}
                  />
                </div>
                {description}
              </li>
            ))}
          </ul>
        )}
        {viewtype === 'projects' && (
          <ul className="grid grid-cols-1 md:grid-cols-3 ">
            {metrics.project_distribution.map((project, index) => (
              <GreenstoryProject
                {...project}
                animatePercentage={startPercentage}
                animationTime={animationTime}
                small
                key={index}
                className="md:m-0 md:mx-md md:first:ml-sm md:last:mr-sm"
              />
            ))}
          </ul>
        )}
        <div className="mt-lg flex flex-row items-center justify-center">
          <Button
            styleType="base"
            to="/green-story"
            className="[svg]:ml-sm flex flex-row items-center justify-center leading-none"
          >
            Powered by <GreenstoryLogo className="ml-sm" />
          </Button>
        </div>
      </div>
    </Block>
  )
}
