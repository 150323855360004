import mergeClasses from 'core/utils/mergeClasses'

export default function Block({
  forceNoPadding,
  className,
  ...props
}: { forceNoPadding?: boolean } & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={mergeClasses('flex overflow-hidden not-last:mb-md', forceNoPadding && '!px-0', className)}
      {...props}
    />
  )
}
