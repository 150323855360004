import { HTMLAttributes } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

export default function Caption({
  small,
  className,
  ...props
}: {
  small?: boolean
  className?: string
} & HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={mergeClasses('text-crimson font-bold', small && 'text-sm', className)}
      {...props}
    />
  )
}
