import Block from './components/Block'
import { PageTagFragment } from './PageTag.dato.generated'

export type { PageTagFragment } from './PageTag.dato.generated'
export const typename = 'PageTagRecord'

export default function PageTag({ tag }: PageTagFragment): JSX.Element {
  return (
    <Block className="mx-auto my-0 flex w-full max-w-content-1/2 items-center justify-center px-md pb-[36px] pt-xl text-center text-2xl">
      {tag}
    </Block>
  )
}
