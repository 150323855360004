'use client'
import useTransparentMenu from 'core/hooks/useTransparentMenu'
import mergeClasses from 'core/utils/mergeClasses'

import PageCollection, { typename as PageCollectionTypename, PageCollectionFragment } from './blocks/PageCollection'
import PageCover, { typename as PageCoverTypename, PageCoverFragment } from './blocks/PageCover'
import PageExternalVideo, {
  typename as PageExternalVideoTypename,
  PageExternalVideoFragment,
} from './blocks/PageExternalVideo'
import PageGreenstory, { typename as PageGreenstoryTypename, PageGreenstoryFragment } from './blocks/PageGreenstory'
import PageHeaderSplit, { typename as PageHeaderSplitTypename, PageHeaderSplitFragment } from './blocks/PageHeaderSplit'
import PageMarquee, { typename as PageMarqueeTypename, PageMarqueeFragment } from './blocks/PageMarquee'
import PageSlider, { typename as PageSliderTypename, PageSliderFragment } from './blocks/PageSlider'
import PageSplit, { typename as PageSplitTypename, PageSplitFragment } from './blocks/PageSplit'
import PageTag, { typename as PageTagTypename, PageTagFragment } from './blocks/PageTag'
import PageText, { typename as PageTextTypename, PageTextFragment } from './blocks/PageText'
import PageTextContainer, {
  typename as PageTextContainerTypename,
  PageTextContainerFragment,
} from './blocks/PageTextContainer'
import PageUSP, { typename as PageUspTypename, PageUspFragment } from './blocks/PageUSP'

type BlockTypes =
  | PageCoverFragment
  | PageMarqueeFragment
  | PageTagFragment
  | PageSliderFragment
  | PageHeaderSplitFragment
  | PageSplitFragment

export default function DatoPage<Tobj>({
  className,
  content,
  nonPage,
}: {
  className?: string
  content: ({ __typename: string; blocks?: {}[] | null } & BlockTypes)[]
  nonPage?: boolean
} & Tobj): JSX.Element | null {
  const hasCoverForFirstBlock: boolean =
    content && content[0]?.__typename === PageCoverTypename && content[0]?.blocks?.length === 1

  useTransparentMenu(hasCoverForFirstBlock)
  return (
    <div
      className={mergeClasses(
        'mb-md',
        !nonPage && '[&>*]:px-md md:[&>*]:px-lg',
        !hasCoverForFirstBlock && ' pt-nav',
        className
      )}
    >
      {content?.map(({ __typename, ...data }, index) => {
        const nextBlockSameType: boolean =
          (content[index + 1] && content[index + 1]?.__typename === __typename) || false

        switch (__typename) {
          case PageCoverTypename:
            return (
              <PageCover
                key={`${index}-${data.id}`}
                {...(data as PageCoverFragment)}
              />
            )
          case PageMarqueeTypename:
            return (
              <PageMarquee
                key={`${index}-${data.id}`}
                {...(data as PageMarqueeFragment)}
              />
            )
          case PageTagTypename:
            return (
              <PageTag
                key={`${index}-${data.id}`}
                {...(data as PageTagFragment)}
              />
            )
          case PageSliderTypename:
            return (
              <PageSlider
                key={`${index}-${data.id}`}
                {...(data as PageSliderFragment)}
              />
            )
          case PageCollectionTypename:
            return (
              <PageCollection
                key={`${index}-${data.id}`}
                {...(data as PageCollectionFragment)}
              />
            )
          case PageHeaderSplitTypename:
            return (
              <PageHeaderSplit
                key={`${index}-${data.id}`}
                {...(data as PageHeaderSplitFragment)}
              />
            )
          case PageGreenstoryTypename:
            return (
              <PageGreenstory
                key={`${index}-${data.id}`}
                {...(data as PageGreenstoryFragment)}
              />
            )
          case PageExternalVideoTypename:
            return (
              <PageExternalVideo
                key={`${index}-${data.id}`}
                {...(data as PageExternalVideoFragment)}
              />
            )
          case PageTextContainerTypename:
            return (
              <PageTextContainer
                key={`${index}-${data.id}`}
                {...(data as PageTextContainerFragment)}
              />
            )
          case PageTextTypename:
            return (
              <PageText
                key={`${index}-${data.id}`}
                {...(data as PageTextFragment)}
              />
            )
          case PageSplitTypename:
            return (
              <PageSplit
                nextSame={nextBlockSameType}
                index={index}
                key={`${index}-${data.id}`}
                {...(data as PageSplitFragment)}
              />
            )
          case PageUspTypename:
            return (
              <PageUSP
                key={`${index}-${data.id}`}
                {...(data as PageUspFragment)}
              />
            )
        }
      })}
    </div>
  )
}
