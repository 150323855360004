import Block from './components/Block'
import DatoText from './components/DatoText'
import { PageTextContainerFragment } from './PageTextContainer.dato.generated'

export type { PageTextContainerFragment } from './PageTextContainer.dato.generated'
export const typename = 'PageTextContainerRecord'

function Text({ body }: PageTextContainerFragment['text'][0]): JSX.Element {
  return (
    <div className="mx-auto my-0 flex w-full max-w-[60vw] flex-col items-start text-left not-last:!mb-lg lg:max-w-[calc(1120px/4)] ">
      <DatoText>{body?.value}</DatoText>
    </div>
  )
}

export default function PageTextContainer({ text }: PageTextContainerFragment): JSX.Element {
  return (
    <Block className="mx-auto my-0 flex w-full max-w-[1120px] flex-col items-start p-md text-left lg:flex-row lg:justify-between">
      {text.map((block, index) => (
        <Text
          {...block}
          key={index}
        />
      ))}
    </Block>
  )
}
