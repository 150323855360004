'use client'

import useViewport from 'core/hooks/useViewport'
import mergeClasses from 'core/utils/mergeClasses'

import viewports from 'components/AdamTheme/theme/viewports'
import HorizontalScroll from 'components/HorizontalScroll'

import Block from './components/Block'
import SliderBlock from './components/SliderBlock'
import { PageSliderFragment } from './PageSlider.dato.generated'

export type { PageSliderFragment } from './PageSlider.dato.generated'
export const typename = 'PageSliderRecord'

import type { VideoQualityType } from './components/Media/Media'

export default function PageSlider({
  title,
  controls,
  compact,
  fill,
  center,
  content,
}: PageSliderFragment): JSX.Element {
  const viewport = useViewport()

  const columnsMap = {
    sm: compact ? 3 : 2,
    md: compact ? 3 : 2,
    lg: compact ? 4 : 3,
    xl: 4,
    '2xl': 4,
    xs: compact ? 3 : 2,
  }

  const wantedColumns = columnsMap[viewport]
  let columns = wantedColumns >= content?.length ? content.length : wantedColumns

  if (!fill) {
    columns += 0.5
  }

  const isCentered = center && viewport === 'xs'

  let maxQuality: VideoQualityType = 'low'
  if (viewport !== 'xs' && columns < 3) {
    maxQuality = 'high'
  } else if (viewport !== 'xs' && columns < 5) {
    maxQuality = 'medium'
  }

  const sizes = Object.entries(columnsMap).reduce((sum, [key, value], index) => {
    const columns = value >= content?.length ? content.length : value
    const vpWidth = viewports[key as keyof typeof viewports]

    if (index !== 0) {
      sum += ', '
    }

    if (vpWidth) {
      return sum + `(max-width: ${vpWidth}px) ${Math.round(vpWidth / columns)}px`
    } else {
      return sum + `${Math.round(100 / columns)}vw`
    }
  }, '')

  return (
    <Block
      className="mx-auto my-0 max-w-content no-select"
      forceNoPadding
    >
      <HorizontalScroll
        className={mergeClasses('!my-0')}
        fill={fill}
        slidesPerViewport={
          !!compact
            ? {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 4,
                xl: 4,
                '2xl': 4,
              }
            : {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                '2xl': 4,
              }
        }
        Component={SliderBlock}
        centeredSlides={isCentered}
        trackingCategory="dato"
        items={content.map((props) => ({ ...props, sizes, maxQuality }))}
        title={title}
        controls={controls}
      />
    </Block>
  )
}
