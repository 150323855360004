export function newLinesToP(text: string): JSX.Element[] {
  return text
    .replace(/^\n/, '')
    .replace(/^<br>/, '')
    .split('\n')
    .map((t, i) => {
      return !t ? <br key={i} /> : <p key={i}>{t}</p>
    })
}

function StringElement({ children }: { children: string }): JSX.Element {
  return <>{children}</>
}

export function newLinesToBrakes(text?: string | null): JSX.Element[] | null {
  if (!text) {
    return null
  }

  const split = text.split('\n').length > 1 ? text.split('\n') : text.split('\\n')
  const formatted = split
    .map((t: string) => t.replace('\n', ''))
    .reduce((result, value, i) => {
      result.push(<StringElement key={i}>{value}</StringElement>)
      result.push(<br key={`br${i}`} />)
      return result
    }, [] as JSX.Element[])

  // Remove last <br/>
  formatted.pop()

  return formatted
}
