import Block from './components/Block'
import DatoText from './components/DatoText'
import { PageTextFragment } from './PageText.dato.generated'

export type { PageTextFragment } from './PageText.dato.generated'
export const typename = 'PageTextRecord'

export default function PageText({ body }: PageTextFragment): JSX.Element {
  return (
    <Block className="mx-auto my-0 flex w-full max-w-content-1/2 p-md">
      <DatoText>{body?.value}</DatoText>
    </Block>
  )
}
